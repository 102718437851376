import React from "react"
import Header from "../components/header"
export default function About() {
  return (
    <div style={{ color: `green` }}>
      <Header headerText="Despre Mihai" />
      <Header headerText="About Gatsby" arbitraryPhrase="is arbitrary" />
      <Header headerText="It's pretty cool" />
      <p>Such wow. Very React.</p>
    </div>
  )
}